import {
    createApp,
    markRaw
} from 'vue'
import {
    createPinia
} from "pinia"
import App from '@/App.vue'
import router from '@/router'
import i18n from "@/libs/i18n";
import svgvendors from "@/assets/svgs";
import '@/assets/scss/index.scss';
import axiosInstance from "./libs/axios"
import VueGoogleMaps from '@fawmi/vue-google-maps'
import toastification from "./libs/toastification";
import "vue-toastification/dist/index.css";
import VueLazyLoad from 'vue3-lazyload'
import loadingImage from "@/assets/images/default-image.webp";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'
import VImageMagnifier from "v-image-magnifier";



const app = createApp(App)
const pinia = createPinia()
app.use(VueFullPage)


toastification(app);
axiosInstance(app);
svgvendors(app);
app.provide("http", app.config.globalProperties.$http)

pinia.use(({
    store
}) => {
    store.$http = markRaw(app.config.globalProperties.$http)
    store.$notificationMessage = markRaw(app.config.globalProperties.$notificationMessage)
    store.$t = markRaw(app.config.globalProperties.$t)
})

app.use(pinia).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBz-jO2cHx_PTk5Y5pdPY7uKqJig78LceU',
        language: 'ar'
    },
})
    .use(VImageMagnifier, {
        // Change options here
        zoomSize: 160,
        zoomFactor: 1,
        // ...
    }).use(router).use(i18n).use(VueLazyLoad, {
        loading: loadingImage,
        error: loadingImage,
    }).component('v-select', vSelect)
    .component('swiper', Swiper)
    .component('swiper-slide', SwiperSlide).mount('#app')