import {
  defineStore
} from "pinia"
import { useAppsStore } from "@/stores/app";

export const useUtilityStore = defineStore({
  id: "utilities",
  state: () => ({
    appData: null,
    homeData: null,
    pageContents: null,
    categories: null,
    partnershipDetails: null,
  }),
  getters: {
    getAppData: state => state.appData,
    getHomeData: state => state.homeData,
    getPageContents: state => state.pageContents,
    getCategories: state => state.categories,
    getPartnershipDetails: state => state.partnershipDetails

  },
  actions: {
    async loadAppData() {
      const store = useAppsStore();

      store.loadingStart();
      return await this.$http.get(`/web/utility`).then(response => {
        store.loadingFinish();
        if (response.status === 200) {
          this.appData = response.data.data
          return true
        }

      }).catch(error => {
        store.loadingFinish();
        throw error
      })
    },

    async loadHomeData() {
      const store = useAppsStore();

      store.loadingStart();
      return await this.$http.get(`/web/utility/home`).then(response => {
        if (response.status === 200) {
          store.loadingFinish();
          this.homeData = response.data.data
          return true
        }

      }).catch(error => {
        store.loadingFinish();
        throw error
      })
    },


    async loadPageContents(data) {
      this.pageContents = null
      const store = useAppsStore();

      store.loadingStart();
      return await this.$http.get(`/web/utility/page-contents`, {
        params: data
      }).then(response => {
        store.loadingFinish();
        if (response.status === 200) {
          this.pageContents = response.data.data
          return true
        }

      }).catch(error => {
        store.loadingFinish();
        throw error
      })
    },

    async loadCategories(data) {
      return await this.$http.get(`/web/utility/categories`, {
        params: data
      }).then(response => {
        if (response.status === 200) {
          this.categories = response.data.data
          return true
        }

      }).catch(error => {
        throw error
      })
    },

    async loadPartnershipDetails(id) {
      return await this.$http.get(`/web/partnerships/detail/${id}`).then(response => {
        if (response.status === 200) {
          this.partnershipDetails = response.data.data
          return true
        }

      }).catch(error => {
        throw error
      })
    },

    async contectUs(data) {
      const store = useAppsStore();

      store.loadingStart();
      return await this.$http.post(`/web/utility/contect-us`, data).then(response => {
        store.loadingFinish();
        this.$notificationMessage({
          title: this.$t('success_messages.send_successfully'),
          type: "success",
        });
        return true
      }).catch(error => {
        store.loadingFinish();
        throw error
      })
    },
  },
})
