<script setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/libs/i18n";
import DropdownMenu from "v-dropdown-menu";
import { useUtilityStore } from "@/stores/utility";
import { getAssetUploadedFilesPath } from "@/helpers/assets";
import vectorImg from "@/assets/images/vector2.webp";

const storeUtility = useUtilityStore();
const { t } = useI18n(); //
const router = useRouter();
const route = useRoute();
const fixedTop = ref(false);
const menuIsOpen = ref(false);
const currentTheme = ref(null);
const itemMenuActive = ref("");
const windowIsMobile = ref(false);

const menuList = ref([
  {
    title: "",
    text: "",
    image: "",
    slug: "SolarSolutions",
    link: "solar-energy-solutions",
    hash: "",
    linkText: "Discover our solutions",
    primaryBtn: false,
  },
  {
    title: "",
    text: "",
    image: "",
    slug: "SolarServices",
    link: "solar-energy-services",
    hash: "",
    linkText: "Discover our services",
    primaryBtn: false,
  },
  {
    title: "",
    text: "",
    image: "",
    slug: "SolarProducts",
    link: "products",
    hash: "solar-energy",
    linkText: "Browse Products",
    primaryBtn: true,
  },

  {
    title: "",
    text: "",
    image: "",
    slug: "ElectricitySolutions",
    link: "electricity-solutions",
    hash: "",
    linkText: "Discover our solutions",
    primaryBtn: false,
  },
  {
    title: "",
    text: "",
    image: "",
    slug: "ElectricityServices",
    link: "electricity-services",
    hash: "",
    linkText: "Discover our services",
    primaryBtn: false,
  },
  {
    title: "",
    text: "",
    image: "",
    slug: "ElectricityProducts",
    link: "products",
    hash: "electricity",
    linkText: "Browse Products",
    primaryBtn: true,
  },

  {
    title: "",
    text: "",
    image: "",
    slug: "BlogNews",
    link: "blog",
    hash: "#news",
    linkText: "Learn more",
    primaryBtn: false,
  },
  {
    title: "",
    text: "",
    image: "",
    slug: "BlogArticles",
    link: "blog",
    hash: "#articles",
    linkText: "Learn more",
    primaryBtn: false,
  },
  {
    title: "",
    text: "",
    image: "",
    slug: "BlogProjects",
    link: "blog",
    hash: "#projects",
    linkText: "Learn more",
    primaryBtn: false,
  },
]);

const scrollFunction = () => {
  const mainNavbarSub = document.getElementById("navbar");
  if (mainNavbarSub) {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      fixedTop.value = true;
    } else {
      fixedTop.value = false;
    }
  }
};

const switchTheme = () => {
  menuIsOpen.value = false;
  currentTheme.value = localStorage.getItem("fl_theme")
    ? localStorage.getItem("fl_theme")
    : "dark";

  if (currentTheme.value == "dark") {
    currentTheme.value = "light";
    localStorage.setItem("fl_theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  } else {
    currentTheme.value = "dark";
    localStorage.setItem("fl_theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  }

  window.dispatchEvent(
    new CustomEvent("fl-theme-localstorage-changed", {
      detail: {
        storage: localStorage.getItem("fl_theme"),
      },
    })
  );

  setTimeout(() => {
    window.location.reload();
  }, 100);
};

const changeLanguage = (code) => {
  menuIsOpen.value = false;
  router
    .push({
      name: router.currentRoute.value.name,
      params: {
        lang: code,
      },
      hash: router.currentRoute.value.hash,
    })
    .then(() => {
      window.location.reload();
    });
};

watch(
  () => [storeUtility.getAppData],
  () => {
    if (
      storeUtility.getAppData?.category_descriptions &&
      storeUtility.getAppData?.category_descriptions.length > 0
    ) {
      const solarEnergyPage =
        storeUtility.getAppData?.category_descriptions.filter((item) => {
          return item.page_slug == "solar-energy";
        });

      if (solarEnergyPage && solarEnergyPage.length > 0) {
        menuList.value[0].title = solarEnergyPage.filter((item) => {
          return item.section_slug == "solutions";
        })[0]?.section_name;
        menuList.value[0].text = solarEnergyPage.filter((item) => {
          return item.section_slug == "solutions";
        })[0]?.description;
        menuList.value[0].image = solarEnergyPage.filter((item) => {
          return item.section_slug == "solutions";
        })[0]?.image;
        menuList.value[1].title = solarEnergyPage.filter((item) => {
          return item.section_slug == "services";
        })[0]?.section_name;
        menuList.value[1].text = solarEnergyPage.filter((item) => {
          return item.section_slug == "services";
        })[0]?.description;
        menuList.value[1].image = solarEnergyPage.filter((item) => {
          return item.section_slug == "services";
        })[0]?.image;
        menuList.value[2].title = solarEnergyPage.filter((item) => {
          return item.section_slug == "products";
        })[0]?.section_name;
        menuList.value[2].text = solarEnergyPage.filter((item) => {
          return item.section_slug == "products";
        })[0]?.description;
        menuList.value[2].image = solarEnergyPage.filter((item) => {
          return item.section_slug == "products";
        })[0]?.image;
      }

      var electricityPage =
        storeUtility.getAppData?.category_descriptions.filter((item) => {
          return item.page_slug == "electricity";
        });

      if (electricityPage && electricityPage.length > 0) {
        menuList.value[3].title = electricityPage.filter((item) => {
          return item.section_slug == "solutions";
        })[0]?.section_name;
        menuList.value[3].text = electricityPage.filter((item) => {
          return item.section_slug == "solutions";
        })[0]?.description;
        menuList.value[3].image = electricityPage.filter((item) => {
          return item.section_slug == "solutions";
        })[0]?.image;
        menuList.value[4].title = electricityPage.filter((item) => {
          return item.section_slug == "services";
        })[0]?.section_name;
        menuList.value[4].text = electricityPage.filter((item) => {
          return item.section_slug == "services";
        })[0]?.description;
        menuList.value[4].image = electricityPage.filter((item) => {
          return item.section_slug == "services";
        })[0]?.image;
        menuList.value[5].title = electricityPage.filter((item) => {
          return item.section_slug == "products";
        })[0]?.section_name;
        menuList.value[5].text = electricityPage.filter((item) => {
          return item.section_slug == "products";
        })[0]?.description;
        menuList.value[5].image = electricityPage.filter((item) => {
          return item.section_slug == "products";
        })[0]?.image;
      }

      var blogPage = storeUtility.getAppData?.category_descriptions.filter(
        (item) => {
          return item.page_slug == "blog";
        }
      );

      if (blogPage && blogPage.length > 0) {
        menuList.value[6].title = blogPage.filter((item) => {
          return item.section_slug == "news";
        })[0]?.section_name;
        menuList.value[6].text = blogPage.filter((item) => {
          return item.section_slug == "news";
        })[0]?.description;
        menuList.value[6].image = blogPage.filter((item) => {
          return item.section_slug == "news";
        })[0]?.image;
        menuList.value[7].title = blogPage.filter((item) => {
          return item.section_slug == "articles";
        })[0]?.section_name;
        menuList.value[7].text = blogPage.filter((item) => {
          return item.section_slug == "articles";
        })[0]?.description;
        menuList.value[7].image = blogPage.filter((item) => {
          return item.section_slug == "articles";
        })[0]?.image;
        menuList.value[8].title = blogPage.filter((item) => {
          return item.section_slug == "projects";
        })[0]?.section_name;
        menuList.value[8].text = blogPage.filter((item) => {
          return item.section_slug == "projects";
        })[0]?.description;
        menuList.value[8].image = blogPage.filter((item) => {
          return item.section_slug == "projects";
        })[0]?.image;
      }
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  var windowWidth = window.innerWidth;
  if (windowWidth <= 1150) {
    windowIsMobile.value = true;
  }
  currentTheme.value = localStorage.getItem("fl_theme")
    ? localStorage.getItem("fl_theme")
    : "dark";
  scrollFunction();
  window.addEventListener("scroll", scrollFunction);
});
</script>
<template>
  <div id="not-fill-navbar"></div>
  <div
    id="navbar"
    :class="[
      fixedTop ? 'navbar fixed-top' : 'navbar',
      menuIsOpen ? 'menu-is-open' : '',
    ]"
  >
    <div class="navbar-sub">
      <button class="menu" @click="menuIsOpen = !menuIsOpen" aria-label="menu">
        <menu-2-rec-icon />
      </button>
      <div class="mobile-logo" v-if="!menuIsOpen">
        <router-link
          :to="{
            name: 'home',
            hash: '#header',
            params: { lang: $i18n.locale },
          }"
          class="brand"
           aria-label="app logo"
        >
          <app-logo v-if="currentTheme == 'dark'" />
          <app-logo-light v-else />
        </router-link>
      </div>
      <div
        class="navbar-content-back"
        @click="menuIsOpen = !menuIsOpen"
        :class="menuIsOpen ? 'show' : ''"
      ></div>
      <div class="navbar-content" :class="menuIsOpen ? 'open' : ''">
        <div class="menu-header">
          <router-link
            :to="{
              name: 'home',
              hash: '#header',
              params: { lang: $i18n.locale },
            }"
            class="brand"
            aria-label="app logo"
          >
            <app-logo v-if="currentTheme == 'dark'" />
            <app-logo-light v-else />
          </router-link>
          <button class="close-menu" aria-label="close-menu" @click="menuIsOpen = !menuIsOpen">
            <cross-rec-icon />
          </button>
        </div>

        <div class="navbar-content-list">
          <router-link
            data-section="header"
            :to="{
              name: 'home',
              hash: '#header',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Home") }}
          </router-link>
          <dropdown-menu
            :overlay="false"
            :dropup="true"
            with-dropdown-closer
            class="dropdown-menu-services"
            :class="{
              'router-link-exact-active':
                $route.name == 'solar-energy-solutions' ||
                $route.name == 'solar-energy-services',
            }"
            :mode="windowIsMobile ? 'click' : 'hover'"
            @mouseenter="itemMenuActive = 'SolarSolutions'"
            :transition="true"
          >
            <template #trigger>
              <div
                data-section="solar-energy"
                class="navbar-content-list-link cursor-pointer"
              >
                {{ $t("navbar.Solar Energy") }}
              </div>
            </template>
            <template #body>
              <div class="top-line"></div>
              <div class="right-line"></div>
              <div class="left-line-2"></div>
              <div class="left-line"></div>
              <div class="bottom-line"></div>
              <div class="dropdown-menu-services-grid">
                <div class="dropdown-menu-services-grid-list">
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @mouseenter="itemMenuActive = 'SolarSolutions'"
                    :class="{ active: itemMenuActive == 'SolarSolutions' }"
                  >
                    {{ $t("common.Solutions") }}
                  </div>
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @mouseenter="itemMenuActive = 'SolarServices'"
                    :class="{ active: itemMenuActive == 'SolarServices' }"
                  >
                    {{ $t("common.Services") }}
                  </div>
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @mouseenter="itemMenuActive = 'SolarProducts'"
                    :class="{ active: itemMenuActive == 'SolarProducts' }"
                  >
                    {{ $t("common.Products") }}
                  </div>
                </div>
                <div
                  class="dropdown-menu-services-grid-details"
                  :style="{
                    backgroundImage: `url(${
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.image
                        ? getAssetUploadedFilesPath(
                            menuList.filter(
                              (item) => item.slug == itemMenuActive
                            )[0]?.image
                          )
                        : vectorImg
                    })`,
                  }"
                >
                  <h1 class="dropdown-menu-services-grid-details-title">
                    {{
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.title
                    }}
                  </h1>
                  <p
                    class="dropdown-menu-services-grid-details-text"
                    v-html="
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.text
                    "
                  ></p>
                  <router-link
                    v-if="
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.hash
                    "
                    :to="{
                      name: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.link,
                      hash: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn
                        ? '#categories'
                        : '#header',
                      params: {
                        lang: $i18n.locale,
                        slug: menuList.filter(
                          (item) => item.slug == itemMenuActive
                        )[0]?.hash,
                      },
                    }"
                    class="dropdown-menu-services-grid-details-link"
                    :class="{
                      primary: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn,
                    }"
                    dropdown-closer
                    @click="menuIsOpen = false"
                  >
                    {{
                      $t(
                        `common.${
                          menuList.filter(
                            (item) => item.slug == itemMenuActive
                          )[0]?.linkText
                        }`
                      )
                    }}
                    <arrow-left-icon />
                  </router-link>
                  <router-link
                    v-else
                    :to="{
                      name: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.link,
                      hash: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn
                        ? '#categories'
                        : '#header',
                      params: {
                        lang: $i18n.locale,
                      },
                    }"
                    class="dropdown-menu-services-grid-details-link"
                    :class="{
                      primary: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn,
                    }"
                    dropdown-closer
                    @click="menuIsOpen = false"
                  >
                    {{
                      $t(
                        `common.${
                          menuList.filter(
                            (item) => item.slug == itemMenuActive
                          )[0]?.linkText
                        }`
                      )
                    }}
                    <arrow-left-icon />
                  </router-link>
                </div>
              </div>
            </template>
          </dropdown-menu>
          <dropdown-menu
            :overlay="false"
            :dropup="true"
            with-dropdown-closer
            class="dropdown-menu-services"
            :class="{
              'router-link-exact-active':
                $route.name == 'electricity-solutions' ||
                $route.name == 'electricity-services',
            }"
            :mode="windowIsMobile ? 'click' : 'hover'"
            @mouseenter="itemMenuActive = 'ElectricitySolutions'"
            :transition="true"
          >
            <template #trigger>
              <div
                data-section="electricity"
                class="navbar-content-list-link cursor-pointer"
              >
                {{ $t("navbar.Electricity") }}
              </div>
            </template>
            <template #body>
              <div class="top-line"></div>
              <div class="right-line"></div>
              <div class="left-line-2"></div>
              <div class="left-line"></div>
              <div class="bottom-line"></div>
              <div class="dropdown-menu-services-grid">
                <div class="dropdown-menu-services-grid-list">
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @mouseenter="itemMenuActive = 'ElectricitySolutions'"
                    :class="{
                      active: itemMenuActive == 'ElectricitySolutions',
                    }"
                  >
                    {{ $t("common.Solutions") }}
                  </div>
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @mouseenter="itemMenuActive = 'ElectricityServices'"
                    :class="{ active: itemMenuActive == 'ElectricityServices' }"
                  >
                    {{ $t("common.Services") }}
                  </div>
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @mouseenter="itemMenuActive = 'ElectricityProducts'"
                    :class="{ active: itemMenuActive == 'ElectricityProducts' }"
                  >
                    {{ $t("common.Products") }}
                  </div>
                </div>
                <div
                  class="dropdown-menu-services-grid-details"
                  :style="{
                    backgroundImage: `url(${
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.image
                        ? getAssetUploadedFilesPath(
                            menuList.filter(
                              (item) => item.slug == itemMenuActive
                            )[0]?.image
                          )
                        : vectorImg
                    })`,
                  }"
                >
                  <h1 class="dropdown-menu-services-grid-details-title">
                    {{
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.title
                    }}
                  </h1>
                  <p
                    class="dropdown-menu-services-grid-details-text"
                    v-html="
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.text
                    "
                  ></p>
                  <router-link
                    v-if="
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.hash
                    "
                    :to="{
                      name: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.link,
                      hash: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn
                        ? '#categories'
                        : '#header',
                      params: {
                        lang: $i18n.locale,
                        slug: menuList.filter(
                          (item) => item.slug == itemMenuActive
                        )[0]?.hash,
                      },
                    }"
                    class="dropdown-menu-services-grid-details-link"
                    :class="{
                      primary: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn,
                    }"
                    dropdown-closer
                    @click="menuIsOpen = false"
                  >
                    {{
                      $t(
                        `common.${
                          menuList.filter(
                            (item) => item.slug == itemMenuActive
                          )[0]?.linkText
                        }`
                      )
                    }}
                    <arrow-left-icon />
                  </router-link>
                  <router-link
                    v-else
                    :to="{
                      name: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.link,
                      hash: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn
                        ? '#categories'
                        : '#header',
                      params: {
                        lang: $i18n.locale,
                      },
                    }"
                    class="dropdown-menu-services-grid-details-link"
                    :class="{
                      primary: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn,
                    }"
                    dropdown-closer
                    @click="menuIsOpen = false"
                  >
                    {{
                      $t(
                        `common.${
                          menuList.filter(
                            (item) => item.slug == itemMenuActive
                          )[0]?.linkText
                        }`
                      )
                    }}
                    <arrow-left-icon />
                  </router-link>
                </div>
              </div>
            </template>
          </dropdown-menu>

          <!-- <dropdown-menu
            :overlay="false"
            :dropup="true"
            with-dropdown-closer
            class="dropdown-menu-services"
            :mode="windowIsMobile ? 'click' : 'hover'"
            @mouseenter="itemMenuActive = 'BlogNews'"
            :transition="true"
          >
            <template #trigger>
              <router-link
                v-if="!windowIsMobile"
                data-section="blog"
                :to="{
                  name: 'blog',
                  hash: '#news',
                  params: { lang: $i18n.locale },
                }"
                class="navbar-content-list-link"
                @click="menuIsOpen = false"
                dropdown-closer
              >
                {{ $t("navbar.Blog") }}
              </router-link>
              <a
                v-if="windowIsMobile"
                data-section="blog"
                class="navbar-content-list-link cursor-pointer"
              >
                {{ $t("navbar.Blog") }}
              </a>
            </template>
            <template #body>
              <div class="top-line"></div>
              <div class="right-line"></div>
              <div class="left-line-2"></div>
              <div class="left-line"></div>
              <div class="bottom-line"></div>
              <div class="dropdown-menu-services-grid">
                <div class="dropdown-menu-services-grid-list">
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @click="itemMenuActive = 'BlogNews'"
                    :class="{
                      active: itemMenuActive == 'BlogNews',
                    }"
                  >
                    {{ $t("sidbar.News") }}
                  </div>
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @click="itemMenuActive = 'BlogArticles'"
                    :class="{ active: itemMenuActive == 'BlogArticles' }"
                  >
                    {{ $t("sidbar.Articles") }}
                  </div>
                  <div
                    class="dropdown-menu-services-grid-list-item"
                    @click="itemMenuActive = 'BlogProjects'"
                    :class="{ active: itemMenuActive == 'BlogProjects' }"
                  >
                    {{ $t("sidbar.Projects") }}
                  </div>
                </div>
                <div
                  class="dropdown-menu-services-grid-details"
                  :style="{
                    backgroundImage: `url(${
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.image
                        ? getAssetUploadedFilesPath(
                            menuList.filter(
                              (item) => item.slug == itemMenuActive
                            )[0]?.image
                          )
                        : vectorImg
                    })`,
                  }"
                >
                  <h1 class="dropdown-menu-services-grid-details-title">
                    {{
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.title
                    }}
                  </h1>
                  <p
                    class="dropdown-menu-services-grid-details-text"
                    v-html="
                      menuList.filter((item) => item.slug == itemMenuActive)[0]
                        ?.text
                    "
                  ></p>
                  <router-link
                    v-if="
                      itemMenuActive == 'BlogNews' ||
                      itemMenuActive == 'BlogArticles' ||
                      itemMenuActive == 'BlogProjects'
                    "
                    :to="{
                      name: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.link,
                      hash: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.hash,
                      params: {
                        lang: $i18n.locale,
                      },
                    }"
                    class="dropdown-menu-services-grid-details-link"
                    :class="{
                      primary: menuList.filter(
                        (item) => item.slug == itemMenuActive
                      )[0]?.primaryBtn,
                    }"
                    dropdown-closer
                    @click="menuIsOpen = false"
                  >
                    {{
                      $t(
                        `common.${
                          menuList.filter(
                            (item) => item.slug == itemMenuActive
                          )[0]?.linkText
                        }`
                      )
                    }}
                    <arrow-left-icon />
                  </router-link>
                </div>
              </div>
            </template>
          </dropdown-menu> -->

          <router-link
            data-section="blog"
            :to="{
              name: 'blog',
              hash: '#news',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Blog") }}
          </router-link>
          <router-link
            data-section="contact-us"
            :to="{
              name: 'contact-us',
              hash: '#contact-us',
              params: { lang: $i18n.locale },
            }"
            class="navbar-content-list-link"
            @click="menuIsOpen = false"
          >
            {{ $t("navbar.Contact Us") }}
          </router-link>
        </div>
        <div class="navbar-content-theme">
          <div
            class="switch-locale"
            @click="changeLanguage($i18n.locale == 'ar' ? 'en' : 'ar')"
          >
            <span>
              {{ $i18n.locale == "ar" ? "EN" : "ع" }}
            </span>
          </div>
          <div class="switch-theme" @click="switchTheme">
            <moon-icon v-if="currentTheme == 'dark'" />
            <sun-icon v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
