<script setup>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useUtilityStore } from "@/stores/utility";
const flTheme = localStorage.getItem("fl_theme");

const storeUtility = useUtilityStore();
const brancheActive = ref(null);
const mapOptions = ref({
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  disableDefaultUi: false,
  styles: [
    {
      elementType: "geometry",
      stylers: [{ color: "#212121" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#212121" }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#181818",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1b1b1b",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#2c2c2c",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8a8a8a",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#373737",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#3c3c3c",
        },
      ],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [
        {
          color: "#4e4e4e",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#3d3d3d",
        },
      ],
    },
  ],
});
const getYear = () => {
  return new Date().getFullYear();
};
const goToTop = () => {
  document.querySelector(".footer").classList.remove("show-footer");
};
const goToGoogleMap = (location) => {
  if (location) {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`,
      "_blank"
    );
  }
};

watch(
  () => [storeUtility.getAppData],
  () => {
    if (
      storeUtility.getAppData?.branches &&
      storeUtility.getAppData?.branches.length > 0
    ) {
      brancheActive.value = storeUtility.getAppData?.branches[0];
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
</script>
<template>
  <footer class="footer">
    <div class="top-line"></div>
    <div class="right-line"></div>
    <div class="left-line"></div>
    <div class="bottom-line"></div>
    <div class="footer-content">
      <div class="scroll-top" id="scroll-top" @click="goToTop">
        <svg
          width="11"
          height="17"
          viewBox="0 0 11 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.188 5.59399L5.594 1.00001L1.00001 5.59399"
            stroke="#F0F0F0"
          />
          <path d="M5.59399 5.4173L5.59399 17" stroke="#F0F0F0" />
        </svg>
      </div>
      <div class="footer-content-grid">
        <div class="footer-content-grid-data">
          <div class="footer-content-grid-data-details">
            <div>
              <app-logo v-if="flTheme == 'dark'" class="footer-logo" />
              <app-logo-light v-else class="footer-logo" />
            </div>
            <div class="footer-links">
              <p class="footer-links-title">{{ $t("common.pages") }}</p>

              <div class="footer-links-list">
                <!-- <router-link
                  :to="{
                    name: 'home',
                    hash: '#header',
                    params: { lang: $i18n.locale },
                  }"
                  class="footer-links-list-link"
                >
                  {{ $t("sidbar.Our Products") }}
                </router-link> -->
                <router-link
                  :to="{
                    name: 'solar-energy-solutions',
                    hash: '#header',
                    params: {
                      lang: $i18n.locale,
                    },
                  }"
                  class="footer-links-list-link"
                >
                  {{ $t("navbar.Solar Energy") }}
                </router-link>
                <router-link
                  :to="{
                    name: 'electricity-solutions',
                    hash: '#header',
                    params: { lang: $i18n.locale },
                  }"
                  class="footer-links-list-link"
                >
                  {{ $t("navbar.Electricity") }}
                </router-link>
                <router-link
                  :to="{
                    name: 'contact-us',
                    hash: 'contact-us',
                    params: { lang: $i18n.locale },
                  }"
                  class="footer-links-list-link"
                >
                  {{ $t("navbar.Contact Us") }}
                </router-link>
                <router-link
                  :to="{
                    name: 'blog',
                    hash: '#news',
                    params: { lang: $i18n.locale },
                  }"
                  class="footer-links-list-link"
                >
                  {{ $t("navbar.Blog") }}
                </router-link>
              </div>
            </div>
            <div class="footer-links">
              <p class="footer-links-title">{{ $t("sidbar.Contact Us") }}</p>

              <div class="footer-links-list">
                <a
                  :href="`tel:${storeUtility.getAppData?.contact_details?.phone_number_1}`"
                  class="footer-links-list-link"
                  dir="ltr"
                >
                  {{ storeUtility.getAppData?.contact_details?.phone_number_1 }}
                </a>
                <a
                  v-if="
                    storeUtility.getAppData?.contact_details?.phone_number_2
                  "
                  :href="`tel:${storeUtility.getAppData?.contact_details?.phone_number_2}`"
                  class="footer-links-list-link"
                  dir="ltr"
                >
                  {{ storeUtility.getAppData?.contact_details?.phone_number_2 }}
                </a>
                <a
                  :href="`mailto:${storeUtility.getAppData?.contact_details?.email}`"
                  class="footer-links-list-link"
                >
                  {{ storeUtility.getAppData?.contact_details?.email }}
                </a>
                <div class="footer-links-list-link">
                  {{ storeUtility.getAppData?.contact_details?.address }}
                </div>
              </div>
            </div>
          </div>
          <div class="footer-content-grid-data-footer d-lab">
            <div>
              <h2 class="footer-content-grid-data-footer-rights">
                {{ $t("common.Developed passionately by ") }}
                <a :href="'https://dev-station.com/'" target="_blank"
                  >DevStation</a
                >
              </h2>
            </div>
            <div>
              <h2 class="footer-content-grid-data-footer-rights">
                {{ $t("common.Copyright reserved") }} -
                {{ getYear() }}
              </h2>
            </div>
            <div class="footer-content-grid-data-footer-social">
              <a
                :href="
                  storeUtility.getAppData?.contact_details?.whatsapp
                    ? `https://wa.me/${storeUtility.getAppData?.contact_details?.whatsapp}`
                    : '#'
                "
                target="_blank"
                aria-label="whatsapp phone"
                ><whatsapp-icon
              /></a>
              <a
                :href="storeUtility.getAppData?.contact_details?.twitter ?? '#'"
                target="_blank"
                aria-label="twitter link"
                ><twitter-icon
              /></a>
              <a
                :href="
                  storeUtility.getAppData?.contact_details?.instagram ?? '#'
                "
                target="_blank"
                 aria-label="instagram link"
                ><instagram-icon
              /></a>
              <a
                :href="
                  storeUtility.getAppData?.contact_details?.facebook ?? '#'
                "
                target="_blank"
                 aria-label="facebook link"
                ><facebook-icon
              /></a>
            </div>
          </div>
        </div>

        <div class="footer-content-grid-map">
          <h2 class="footer-content-grid-map-title">
            {{ $t("common.Location") }}
          </h2>
          <div
            class="footer-content-grid-map-branches"
            v-if="
              storeUtility.getAppData?.branches &&
              storeUtility.getAppData?.branches.length > 0
            "
          >
            <div
              class="footer-content-grid-map-branches-item"
              v-for="(item, index) in storeUtility.getAppData?.branches"
              :key="index"
              @click="brancheActive = item"
              :class="brancheActive?.id == item.id ? 'active' : ''"
            >
              <div class="footer-content-grid-map-branches-item-icon"></div>
              <p class="footer-content-grid-map-branches-item-text">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="footer-content-grid-map-location" v-if="brancheActive">
            <GMapMap
              :center="{
                lat: parseFloat(brancheActive?.geo_location?.lat),
                lng: parseFloat(brancheActive?.geo_location?.lng),
              }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 500px"
              :options="mapOptions"
              @click="goToGoogleMap(brancheActive?.geo_location)"
            >
              <GMapMarker
                :position="{
                  lat: parseFloat(brancheActive?.geo_location?.lat),
                  lng: parseFloat(brancheActive?.geo_location?.lng),
                }"
              />
            </GMapMap>
          </div>
        </div>
        <div class="footer-content-grid-data-footer d-mobile">
          <div>
            <h2 class="footer-content-grid-data-footer-rights">
              {{ $t("common.Developed passionately by ") }}
              <a :href="'https://dev-station.com/'" target="_blank"
                >DevStation</a
              >
            </h2>
          </div>
          <div>
            <h2 class="footer-content-grid-data-footer-rights">
              {{ $t("common.Copyright reserved") }} -
              {{ getYear() }}
            </h2>
          </div>
          <div class="footer-content-grid-data-footer-social">
            <a
              :href="
                storeUtility.getAppData?.contact_details?.whatsapp
                  ? `https://wa.me/${storeUtility.getAppData?.contact_details?.whatsapp}`
                  : '#'
              "
              target="_blank"
              aria-label="whatsapp phone"
              ><whatsapp-icon
            /></a>
            <a
              :href="storeUtility.getAppData?.contact_details?.twitter ?? '#'"
              target="_blank"
              aria-label="twitter link"
              ><twitter-icon
            /></a>
            <a
              :href="storeUtility.getAppData?.contact_details?.instagram ?? '#'"
              target="_blank"
              aria-label="instagram link"
              ><instagram-icon
            /></a>
            <a
              :href="storeUtility.getAppData?.contact_details?.facebook ?? '#'"
              target="_blank"
              aria-label="facebook link"
              ><facebook-icon
            /></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>